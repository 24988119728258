import { Appbar } from "../components/Appbar";
import { Heading } from "../components/Heading";
import { ProjectCard } from "../components/ProjectCard";
import { ExperienceAccordion } from "../components/ExperienceAccordion";
import "./App.css";

/**
 * This file is going to act as our main component for content.
 * We'll leverage other components to help flesh out our app.
 */

export function App() {
  return (
    <>
      <Appbar />
      <main id="Home">
        <section id="landing">
          <h1 className="headline">
            My name is <span>Matt</span>, and I build apps to improve the lives
            of others.
          </h1>
          <img
            alt="Software engineer illustration"
            src="/assets/software_engineer.svg"
            width="90%"
          />
          <article className="about-me">
            <Heading id="Home">About Me</Heading>
            <p>My name really is Matt and I do cool stuff.</p>
          </article>
        </section>
        <section className="projects-section">
          <div className="blue-box" aria-hidden="true" />
          <Heading id="Projects">Projects</Heading>
          <div className="project-cards">
            <ProjectCard
              title="Project 1"
              description="A brief description of project 1."
            />
            <ProjectCard
              title="Project 2"
              description="A brief description of project 2."
            />
            <ProjectCard
              title="Project 3"
              description="A brief description of project 3."
            />
          </div>
        </section>
        <section>
          <Heading id="Experience">Experience</Heading>
          <div className="experience-accordions">
            <ExperienceAccordion title="Software Engineering Course">
              Undergrad 3000 level web course.
            </ExperienceAccordion>
            <ExperienceAccordion title="Coding Internship">
              My first internship!
            </ExperienceAccordion>
            <ExperienceAccordion title="This awesome React workshop">
              This super awesome React workshop I did thanks to Girls Who Code.
            </ExperienceAccordion>
          </div>
        </section>
      </main>
    </>
  );
}
